import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form, useFormikContext } from "formik";
import { PersistFormikValues } from "formik-persist-values";

//import Welcome from "./Forms/Welcome";
import DriverInfo from "./Forms/DriverInfoSignature";
import PDFViewer from "./Forms/PDFViewerSigned";
import Documents from "./Forms/Documents";
import PaymentForm from "./Forms/PaymentForm";

import validationSchema from "./FormModel/validationSchema";
import formInitialValues from "./FormModel/formInitialValues";
import onboardingFormModel from "./FormModel/onboardingFormModel";
import useStyles from "./styles";
import { API_FUNCTIONS } from "../../config/api";
import { dataIsDriver } from "../api/data";

let url = API_FUNCTIONS;
const query = new URLSearchParams(window.location.search);
const token = query.get("i");

let name = "";
localStorage.setItem("name", name);

if (token) {
  fetch(`${url}/people/sql/${token}/onboarding`)
    .then((response) => response.json())
    .then(function(data) {
      name = !data.name ? "" : data.name;
      localStorage.setItem("name", name);
    })
    .catch((error) => console.log(error));
}
const { formId, formField } = onboardingFormModel;

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const DisplayFieldErrorSummary = () => {
  const { submitCount, isValid, errors } = useFormikContext();
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) {
      setErrorMessage(null);
    } else {
      setErrorMessage(
        <div style={{ color: "red" }}>
          Please scroll up to review any errors.
        </div>
      );
    }
  }, [submitCount, isValid, errors]);

  return errorMessage;
};

function _renderStepContent(step, isDriver) {
  switch (step) {
    case 0:
      //return <Welcome formField={formField} />;
      return <DriverInfo formField={formField} isDriver={isDriver} />;

    case 1:
      return <PDFViewer formField={formField} isDriver={isDriver} />;
    case 2:
      //return <DriverInfo formField={formField} />;
      return <Documents formField={formField} isDriver={isDriver} />;
    case 3:
      window.scrollTo(0, 0);
      return <PaymentForm formField={formField} isDriver={isDriver} />;
    default:
      window.scrollTo(0, 0);
      return <div>Not Found</div>;
  }
}

export default function GettingStarted() {
  const [isDriver, setIsDriver] = useState();
  const [userId, setUserId] = useState();

  var savedLastStep_Onboarding = !localStorage.getItem(
    "savedLastStep_Onboarding"
  )
    ? 0
    : localStorage.getItem("savedLastStep_Onboarding");

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(
    parseInt(savedLastStep_Onboarding)
  );

  useEffect(() => {
    console.log("useEffect driver app");
    setUserId(token);
    // rest steps if new invite
    const previousInvite = localStorage.getItem("i");
    if (previousInvite !== token) {
      setActiveStep(0);
      localStorage.setItem("savedLastStep_Onboarding", 0);
      localStorage.setItem("driverInfo", "");
      localStorage.setItem("fileLink", "");

      localStorage.removeItem("signaturePageNumber3");
      localStorage.removeItem("signaturePageNumber8");
      localStorage.removeItem("signaturePageNumber9");
      localStorage.removeItem("signaturePageNumber10");
      localStorage.removeItem("signaturePageNumber11");
    }

    localStorage.setItem("i", token);
  }, []);

  useEffect(async () => {
    if (userId) {
      console.log("useEffect userId", userId);
      const data = await dataIsDriver(userId).then((data) => {
        setIsDriver(data);
      });
    }
  }, [userId]);

  useEffect(() => {
    console.log("useEffect isDriver", isDriver);
  }, [isDriver]);

  const steps = [
    isDriver ? "Driver Info" : "Monitor Info",
    "Contract Review ",
    "Upload Docs",
    "Payment",
  ];
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      localStorage.setItem("savedLastStep_Onboarding", activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        {isDriver ? "Driver Application" : "Monitor Application"}
      </Typography>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                <ScrollToFieldError />
                {_renderStepContent(activeStep, isDriver)}
                {activeStep == 0 && <DisplayFieldErrorSummary />}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    {!isLastStep && (
                      <Button
                        disabled={isSubmitting || activeStep == 3}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? "All done" : "Next"}
                      </Button>
                    )}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
                <PersistFormikValues name="driverInfo" />
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

import { createStore } from "devextreme-aspnet-data-nojquery";
import { API_FUNCTIONS } from "../../config/api";

const url = API_FUNCTIONS;

export const dataIsDriver = async (token) => {
  if (token) {
    return fetch(`${url}/people/sql/${token}/onboarding`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      return response.json().then((data) => {
        return data.isDriver;
      });
    });
  }
};

export const dataRoute = async (props) => {
  let routeId = null;
  let uid = null;

  if (props) {
    routeId = props.routeId ? props.routeId : null;
    uid = props.uid ? props.uid : null;
  }

  return fetch(`${url}/routes/sql/${routeId}/driver?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteStops = (props) => {
  let routeId = null;
  let uid = null;
  let group = null;

  if (props) {
    routeId = props.routeId ? props.routeId : null;
    uid = props.uid ? props.uid : null;
    group = props.group ? props.group : null;
  }

  return createStore({
    key: "id",
    loadUrl: `${url}/routes/sql/${routeId}/stops/driver?uid=${uid}&group=${group}`,
    loadMode: "raw",
  });
};

export const dataRouteTrip = async (props) => {
  let routeId = null;
  let uid = null;
  let group = null;

  if (props) {
    routeId = props.routeId ? props.routeId : null;
    uid = props.uid ? props.uid : null;
    group = props.group ? props.group : null;
  }

  return fetch(
    `${url}/routes/sql/${routeId}/trip/driver?uid=${uid}&group=${group}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => response.json());
};

export const dataRouteGroups = async (props) => {
  let routeId = null;
  let uid = null;

  if (props) {
    routeId = props.routeId ? props.routeId : null;
    uid = props.uid ? props.uid : null;
  }

  return fetch(`${url}/routes/sql/${routeId}/groups/driver?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteOfferAccept = async (routeId, uid) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/accept/driver?uid=${uid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ driverId: uid }),
  }).then(async (response) => {
    return await response.json();
  });
};

import React from "react";
import { storage } from "../../../auth/FirebaseAuth";
import { notification, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { API_FUNCTIONS } from "../../../config/api";

let url = API_FUNCTIONS;
//if (window.location.hostname === //"localhost") {
//  url = API_FUNCTIONS_LOCAL;
//}

const props = {
  name: "file",
  multiple: true,
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class UploadDoc extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.peopleId = props.peopleId;
    this.name = props.name;
    this.state = {
      dataSource: [],
      uploadFiles: [],
    };
  }

  componentDidMount() {
    console.log("** id**");
    console.log(this.id);
    fetch(
      `${url}/peopleObRequirement/sql/${this.peopleId}/${this.id}/requirement-files`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function(response) {
        //console.log(response);
        return response.json();
      })
      .then((myJson) => {
        //console.log(myJson);
        this.setState({ uploadFiles: myJson });
        console.log("** req files **");
        console.log(this.state.uploadFiles);
      });
  }

  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  //handleChange = ({ fileList }) => this.setState({ fileList });

  uploadFile = async (e) => {
    switch (e.file.status) {
      case "uploading":
        const isJpgOrPng = e.type === "image/jpeg" || e.type === "image/png";
        if (isJpgOrPng) {
          notification.open({
            message: "Profile Image",
            description: "Please select a jpeg or png image",
            style: { backgroundColor: "#fae4de" },
            duration: 2.5,
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
          return;
        }

        console.log("uploading...");

        storage
          .ref(this.peopleId + "/" + this.id + "/" + e.file.name)
          .put(e.file.originFileObj)
          .then((snapshot) => {
            return snapshot.ref.getDownloadURL();
          })
          .then((urlSaved) => {
            // fetch post
            fetch(
              `${url}/peopleObRequirement/sql/${this.peopleId}/requirement/${this.id}/requirement-files`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  name: e.file.name,
                  url: urlSaved,
                  peopleObRequirementId: this.id,
                  peopleId: this.peopleId,
                  createDate: Date.now(),
                }),
              }
            ).then((response) => {
              fetch(
                `${url}/peopleObRequirement/sql/${this.peopleId}/${this.id}/requirement-files`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                }
              )
                .then(function(response) {
                  //console.log(response);
                  return response.json();
                })
                .then((myJson) => {
                  //console.log(myJson);
                  this.setState({ uploadFiles: myJson });
                  console.log("** req files **");
                  console.log(this.state.uploadFiles);
                });
            });
          })
          .catch((error) => {
            console.log("** File Post Error **");
            console.log(error);
          });
        break;
      case "removed":
        // Create a reference to the file to delete
        const desertRef = storage.refFromURL(e.file.url);

        const fileId = e.file.id;
        const peopleId = e.file.peopleId;
        const peopleObRequirementId = e.file.peopleObRequirementId;

        // delete from database
        fetch(
          `${url}/peopleObRequirement/sql/${peopleId}/requirement/${peopleObRequirementId}/requirement-files/${fileId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        ).then((response) => {
          console.log(response);
          fetch(
            `${url}/peopleObRequirement/sql/${peopleId}/${peopleObRequirementId}/requirement-files`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
            .then((response) => {
              //console.log(response);
              return response.json();
            })
            .then((myJson) => {
              //console.log(myJson);
              this.setState({ uploadFiles: myJson });
            });
        });

        // Delete the file from storage
        desertRef
          .delete()
          .then(() => {
            // File deleted successfully
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
        break;
    }
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <>
        <div>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </div>
      </>
    );
    return (
      <>
        <Upload
          accept="image/*,.pdf,.doc,.docx"
          listType="picture-card"
          fileList={this.state.uploadFiles}
          onChange={this.uploadFile}
        >
          {uploadButton}
        </Upload>
      </>
    );
  }
}
export default UploadDoc;

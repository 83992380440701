import React, { useEffect, useState } from "react";
import { CheckboxField } from "../../FormFields";
import GoogleDocsViewer from "react-google-docs-viewer";
import ReactLoading from "react-loading";

import { API_FUNCTIONS } from "../../../config/api";

let url = API_FUNCTIONS;
//if (window.location.hostname === //"localhost") {
//  url = API_FUNCTIONS_LOCAL;
//}

const query = new URLSearchParams(window.location.search);
const token = query.get("i");

export default function App(props) {
  const [pdfEncoded, setPdfEncoded] = useState("assets/Loading.pdf");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isDriver, setIsDriver] = useState();
  const [type, setType] = useState("driver");

  const {
    formField: { chkReviewSign },
  } = props;

  useEffect(() => {
    setIsDriver(props.isDriver);
  }, [props.isDriver]);

  useEffect(() => {
    if (isDriver) {
      setType("driver");
    } else {
      setType("monitor");
    }
  }, [isDriver]);

  useEffect(async () => {
    // save data
    const formData = JSON.stringify(localStorage.getItem("driverInfo"));

    const requestOptions = {
      method: "PUT",
      body: formData,
    };

    fetch(
      `${url}/people/sql/onboarding/applicationData/${token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setIsSaved(true);
      });
  }, []);

  useEffect(async () => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    const requestOptions = {
      method: "GET",
      cache: "no-store",
    };

    await fetch(
      `${url}/people/sql/onboarding/applicationPdf/${token}?type=${type}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setPdfEncoded(encodeURIComponent(data.applicationUrl, "UTF-8"));
        //setIsLoading(false);
        return;
      });

    await fetch(
      `${url}/people/sql/onboarding/applicationPdfAdmin/${token}?type=${type}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // setPdfEncoded(encodeURIComponent(data.applicationUrl, "UTF-8"));
        setIsLoading(false);
        return;
      });
  }, [isSaved]);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div>
          {!isLoading ? (
            <GoogleDocsViewer
              width="100%"
              height="500px"
              fileUrl={pdfEncoded}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                width: "50%",
              }}
            >
              <ReactLoading
                type={"spin"}
                color={"#4285F4"}
                height={100}
                width={100}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ textAlign: "right", paddingTop: "10px" }}>
        <hr />
      </div>
      <div
        style={{
          textAlign: "right",
          paddingTop: "10px",
          whiteSpace: "nowrap",
        }}
      >
        <CheckboxField name={chkReviewSign.name} label={chkReviewSign.label} />
      </div>
    </>
  );
}

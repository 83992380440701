import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { storage } from "../../../auth/FirebaseAuth";
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
} from "../../FormFields";
import "antd/dist/antd.css";
import MaskedInput from "react-text-mask";

import SignaturePadWrapper from "react-signature-pad-wrapper";
import styles from "./styles.module.css";
import sigStyles from "./sigStyles.module.css";
import { useFormikContext, useField } from "formik";

import { API_FUNCTIONS } from "../../../config/api";

let url = API_FUNCTIONS;
const query = new URLSearchParams(window.location.search);
const token = query.get("i");

function TextMaskPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function TextMaskSSN(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function TextMaskEmployerID(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const states = [
  {
    label: null,
    value: null,
  },
  {
    label: "AL",
    value: "AL",
  },
  {
    label: "AK",
    value: "AK",
  },
  {
    label: "AZ",
    value: "AZ",
  },
  {
    label: "AR",
    value: "AR",
  },
  {
    label: "CA",
    value: "CA",
  },
  {
    label: "CO",
    value: "CO",
  },
  {
    label: "CT",
    value: "CT",
  },
  {
    label: "DE",
    value: "DE",
  },
  {
    label: "FL",
    value: "FL",
  },
  {
    label: "GA",
    value: "GA",
  },
  {
    label: "HI",
    value: "HI",
  },
  {
    label: "ID",
    value: "ID",
  },
  {
    label: "IL",
    value: "IL",
  },
  {
    label: "IN",
    value: "IN",
  },
  {
    label: "IA",
    value: "IA",
  },
  {
    label: "KS",
    value: "KS",
  },
  {
    label: "KY",
    value: "KY",
  },
  {
    label: "LA",
    value: "LA",
  },
  {
    label: "ME",
    value: "ME",
  },
  {
    label: "MD",
    value: "MD",
  },
  {
    label: "MA",
    value: "MA",
  },
  {
    label: "MI",
    value: "MI",
  },
  {
    label: "MN",
    value: "MN",
  },
  {
    label: "MS",
    value: "MS",
  },
  {
    label: "MO",
    value: "MO",
  },
  {
    label: "MT",
    value: "MT",
  },
  {
    label: "NE",
    value: "NE",
  },
  {
    label: "NV",
    value: "NV",
  },
  {
    label: "NH",
    value: "NH",
  },
  {
    label: "NJ",
    value: "NJ",
  },
  {
    label: "NM",
    value: "NM",
  },
  {
    label: "NY",
    value: "NY",
  },
  {
    label: "NC",
    value: "NC",
  },
  {
    label: "ND",
    value: "ND",
  },
  {
    label: "OH",
    value: "OH",
  },
  {
    label: "OK",
    value: "OK",
  },
  {
    label: "OR",
    value: "OR",
  },
  {
    label: "PA",
    value: "PA",
  },
  {
    label: "RI",
    value: "RI",
  },
  {
    label: "SC",
    value: "SC",
  },
  {
    label: "SD",
    value: "SD",
  },
  {
    label: "TN",
    value: "TN",
  },
  {
    label: "TX",
    value: "TX",
  },
  {
    label: "UT",
    value: "UT",
  },
  {
    label: "VT",
    value: "VT",
  },
  {
    label: "VA",
    value: "VA",
  },
  {
    label: "WA",
    value: "WA",
  },
  {
    label: "WV",
    value: "WV",
  },
  {
    label: "WI",
    value: "WI",
  },
  {
    label: "WY",
    value: "WY",
  },
];

const dayTimes = [
  {
    label: null,
    value: null,
  },
  {
    value: "Monday AM",
    label: "Monday AM",
  },
  {
    value: "Monday PM",
    label: "Monday PM",
  },
  {
    value: "Tuesday AM",
    label: "Tuesday AM",
  },
  {
    value: "Tuesday PM",
    label: "Tuesday PM",
  },

  {
    value: "Wednesday AM",
    label: "Wednesday AM",
  },
  {
    value: "Wednesday PM",
    label: "Wednesday PM",
  },
  {
    value: "Thursday AM",
    label: "Thursday AM",
  },
  {
    value: "Thursday PM",
    label: "Thursday PM",
  },
  {
    value: "Friday AM",
    label: "Friday AM",
  },
  {
    value: "Friday PM",
    label: "Friday PM",
  },
];

const vehicleClassList = [
  {
    label: null,
    value: null,
  },
  {
    value: "SEDAN",
    label: "SEDAN",
  },

  {
    value: "SUV",
    label: "SUV",
  },

  {
    value: "VAN",
    label: "VAN",
  },

  {
    value: "TRUCK",
    label: "TRUCK",
  },

  {
    value: "BUS",
    label: "BUS",
  },
];

const vehicleMakeList = [
  {
    label: null,
    value: null,
  },
  { label: "Audi", value: "Audi" },
  { label: "BMW", value: "BMW" },
  { label: "Buick", value: "Buick" },
  { label: "Cadillac", value: "Cadillac" },
  { label: "Chevrolet", value: "Chevrolet" },
  { label: "Chrysler", value: "Chrysler" },
  { label: "Dodge", value: "Dodge" },
  { label: "Ferrari", value: "Ferrari" },
  { label: "Ford", value: "Ford" },
  { label: "GM", value: "GM" },
  { label: "GEM", value: "GEM" },
  { label: "GMC", value: "GMC" },
  { label: "Honda", value: "Honda" },
  { label: "Hummer", value: "Hummer" },
  { label: "Hyundai", value: "Hyundai" },
  { label: "Infiniti", value: "Infiniti" },
  { label: "Isuzu", value: "Isuzu" },
  { label: "Jaguar", value: "Jaguar" },
  { label: "Jeep", value: "Jeep" },
  { label: "Kia", value: "Kia" },
  { label: "Lamborghini", value: "Lamborghini" },
  { label: "Land Rover", value: "Land Rover" },
  { label: "Lexus", value: "Lexus" },
  { label: "Lincoln", value: "Lincoln" },
  { label: "Lotus", value: "Lotus" },
  { label: "Mazda", value: "Mazda" },
  { label: "Mercedes-Benz", value: "Mercedes-Benz" },
  { label: "Mercury", value: "Mercury" },
  { label: "Mitsubishi", value: "Mitsubishi" },
  { label: "Nissan", value: "Nissan" },
  { label: "Oldsmobile", value: "Oldsmobile" },
  { label: "Peugeot", value: "Peugeot" },
  { label: "Pontiac", value: "Pontiac" },
  { label: "Porsche", value: "Porsche" },
  { label: "Regal", value: "Regal" },
  { label: "Saab", value: "Saab" },
  { label: "Saturn", value: "Saturn" },
  { label: "Subaru", value: "Subaru" },
  { label: "Suzuki", value: "Suzuki" },
  { label: "Toyota", value: "Toyota" },
  { label: "Volkswagen", value: "Volkswagen" },
  { label: "Volvo", value: "Volvo" },
];

const vehicleColorList = [
  {
    label: null,
    value: null,
  },
  { label: "Beige", value: "Beige" },
  { label: "Black", value: "Black" },
  { label: "Blue", value: "Blue" },
  { label: "Brown", value: "Brown" },
  { label: "Charcoal", value: "Charcoal" },
  { label: "Coffee", value: "Coffee" },
  { label: "Crimson Red", value: "Crimson Red" },
  { label: "Gold", value: "Gold" },
  { label: "Gray", value: "Gray" },
  { label: "Green", value: "Green" },
  { label: "Light Blue", value: "Light Blue" },
  { label: "Lime Green", value: "Lime Green" },
  { label: "Navy Blue", value: "Navy Blue" },
  { label: "Orange", value: "Orange" },
  { label: "Pewter", value: "Pewter" },
  { label: "Pink", value: "Pink" },
  { label: "Purple", value: "Purple" },
  { label: "Red", value: "Red" },
  { label: "Silver", value: "Silver" },
  { label: "Tan", value: "Tan" },
  { label: "Violet", value: "Violet" },
  { label: "White", value: "White" },
  { label: "Wine Red", value: "Wine Red" },
  { label: "Yellow", value: "Yellow" },
];

const listYesNo = [
  {
    label: null,
    value: null,
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const genderList = [
  {
    label: null,
    value: null,
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Male",
    label: "Male",
  },
];

const eyeColorList = [
  {
    label: null,
    value: null,
  },
  { label: "Black", value: "Black" },
  { label: "Blue", value: "Blue" },
  { label: "Brown", value: "Brown" },
  { label: "Gray", value: "Gray" },
  { label: "Green", value: "Green" },
  { label: "Hazel", value: "Hazel" },
  { label: "Pink", value: "Pink" },
  { label: "Marron", value: "Marron" },
  { label: "Mulitcolor", value: "Mulitcolor" },
  { label: "Unknown", value: "Unknown" },
];

const hairColorList = [
  {
    label: null,
    value: null,
  },
  { label: "Black", value: "Black" },
  { label: "Brown", value: "Brown" },
  { label: "Blond or Strawberry", value: "Blond or Strawberry" },
  { label: "Gray or Partially Gray", value: "Gray or Partially Gray" },
  { label: "Bald", value: "Bald" },
  { label: "Red or Auburn", value: "Red or Auburn" },
  { label: "Sandy", value: "Sandy" },
  { label: "White", value: "White" },
  { label: "Blue", value: "Blue" },
  { label: "Green", value: "Green" },
  { label: "Pink", value: "Pink" },
  { label: "Orange", value: "Orange" },
  { label: "Purple", value: "Purple" },
  { label: "Unknown", value: "Unknown" },
];

const businessEntityList = [
  {
    label: null,
    value: null,
  },
  {
    value: "1",
    label: "Individual/sole proprietor or single-member LLC",
  },
  {
    value: "2",
    label: "C Corporation",
  },
  {
    value: "3",
    label: "S Corporation",
  },
  {
    value: "4",
    label: "Partnership",
  },
  {
    value: "5",
    label: "Trust/estate",
  },
  {
    value: "6",
    label: "LLC",
  },
];

const workerStatusList = [
  {
    label: null,
    value: null,
  },
  {
    value: "1",
    label: "A citizen of the United States",
  },
  {
    value: "2",
    label: "A noncitizen national of the United States",
  },
  {
    value: "3",
    label: "A lawful permanent resident",
  },
  {
    value: "4",
    label: "An alien authorized to work",
  },
];

const alienAuthorizedDocTypeList = [
  {
    label: null,
    value: null,
  },
  {
    value: "1",
    label: "An alien registration number/USCIS number",
  },
  {
    value: "2",
    label: "A form I-94 admission number",
  },
  {
    value: "3",
    label: "A foreign passport number",
  },
];

const vehicleYearList = [
  {
    label: null,
    value: null,
  },
  {
    value: "2013",
    label: "2013",
  },
  {
    value: "2014",
    label: "2014",
  },
  {
    value: "2015",
    label: "2015",
  },
  {
    value: "2016",
    label: "2016",
  },
  {
    value: "2017",
    label: "2017",
  },
  {
    value: "2018",
    label: "2018",
  },
  {
    value: "2019",
    label: "2019",
  },
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2024",
    label: "2024",
  },
  {
    value: "2025",
    label: "2025",
  },
];

const wcVanType = [
  {
    label: null,
    value: null,
  },
  {
    value: "Side Load",
    label: "Side Load",
  },

  {
    value: "Rear Load",
    label: "Rear Load",
  },
];

const count10 = [
  {
    label: null,
    value: null,
  },
  {
    value: "1",
    label: "1",
  },

  {
    value: "2",
    label: "2",
  },

  {
    value: "3",
    label: "3",
  },

  {
    value: "4",
    label: "4",
  },

  {
    value: "5",
    label: "5",
  },

  {
    value: "6",
    label: "6",
  },

  {
    value: "7",
    label: "7",
  },

  {
    value: "8",
    label: "8",
  },

  {
    value: "9",
    label: "9",
  },

  {
    value: "10",
    label: "10",
  },
];

const heightList = [
  {
    value: "4 ft 0 in or less",
    label: "4 ft 0 in (121.9cm) or less",
  },
  {
    value: "4 ft 1 in",
    label: "4 ft 1 in (124.5cm)",
  },
  {
    value: "4 ft 2 in",
    label: "4 ft 2 in (127.0cm)",
  },
  {
    value: "4 ft 3 in",
    label: "4 ft 3 in (129.5cm)",
  },
  {
    value: "4 ft 4 in",
    label: "4 ft 4 in (132.1cm)",
  },
  {
    value: "4 ft 5 in",
    label: "4 ft 5 in (134.6cm)",
  },
  {
    value: "4 ft 6 in",
    label: "4 ft 6 in (137.2cm)",
  },
  {
    value: "4 ft 7 in",
    label: "4 ft 7 in (139.7cm)",
  },
  {
    value: "4 ft 8 in",
    label: "4 ft 8 in (142.2cm)",
  },
  {
    value: "4 ft 9 in",
    label: "4 ft 9 in (144.8cm)",
  },
  {
    value: "4 ft 10 in",
    label: "4 ft 10 in (147.3cm)",
  },
  {
    value: "4 ft 11 in",
    label: "4 ft 11 in (149.9cm)",
  },
  {
    value: "5 ft 0 in",
    label: "5 ft 0 in (152.4cm)",
  },
  {
    value: "5 ft 1 in",
    label: "5 ft 1 in (154.9cm)",
  },
  {
    value: "5 ft 2 in",
    label: "5 ft 2 in (157.5cm)",
  },
  {
    value: "5 ft 3 in",
    label: "5 ft 3 in (160.0cm)",
  },
  {
    value: "5 ft 4 in",
    label: "5 ft 4 in (162.6cm)",
  },
  {
    value: "5 ft 5 in",
    label: "5 ft 5 in (165.1cm)",
  },
  {
    value: "5 ft 6 in",
    label: "5 ft 6 in (167.6cm)",
  },
  {
    value: "5 ft 7 in",
    label: "5 ft 7 in (170.2cm)",
  },
  {
    value: "5 ft 8 in",
    label: "5 ft 8 in (172.7cm)",
  },
  {
    value: "5 ft 9 in",
    label: "5 ft 9 in (175.3cm)",
  },
  {
    value: "5 ft 10 in",
    label: "5 ft 10 in (177.8cm)",
  },
  {
    value: "5 ft 11 in",
    label: "5 ft 11 in (180.3cm)",
  },
  {
    value: "6 ft 0 in",
    label: "6 ft 0 in (182.9cm)",
  },
  {
    value: "6 ft 1 in",
    label: "6 ft 1 in (185.4cm)",
  },
  {
    value: "6 ft 2 in",
    label: "6 ft 2 in (187.9cm)",
  },
  {
    value: "6 ft 3 in",
    label: "6 ft 3 in (190.5cm)",
  },
  {
    value: "6 ft 4 in",
    label: "6 ft 4 in (193.0cm)",
  },
  {
    value: "6 ft 5 in",
    label: "6 ft 5 in (195.6cm)",
  },
  {
    value: "6 ft 6 in",
    label: "6 ft 6 in (198.1cm)",
  },
  {
    value: "6 ft 7 in",
    label: "6 ft 7 in (200.7cm)",
  },
  {
    value: "6 ft 8 in",
    label: "6 ft 8 in (203.2cm)",
  },
  {
    value: "6 ft 9 in",
    label: "6 ft 9 in (205.7cm)",
  },
  {
    value: "6 ft 10 in",
    label: "6 ft 10 in (208.3cm)",
  },
  {
    value: "6 ft 11 in",
    label: "6 ft 11 in (210.8cm)",
  },
  {
    value: "7 ft 0 in",
    label: "7 ft 0 in (213.4cm)",
  },
  {
    value: "7 ft 1 in",
    label: "7 ft 1 in (215.9cm)",
  },
  {
    value: "7 ft 2 in",
    label: "7 ft 2 in (218.4cm)",
  },
  {
    value: "7 ft 3 in",
    label: "7 ft 3 in (220.9cm)",
  },
  {
    value: "7 ft 4 in",
    label: "7 ft 4 in (223.5cm)",
  },
  {
    value: "7 ft 5 in",
    label: "7 ft 5 in (226.0cm)",
  },
  {
    value: "7 ft 6 in",
    label: "7 ft 6 in (228.6cm)",
  },
  {
    value: "7 ft 7 in",
    label: "7 ft 7 in (231.1cm)",
  },
  {
    value: "7 ft 8 in",
    label: "7 ft 8 in (233.7cm)",
  },
  {
    value: "7 ft 9 in",
    label: "7 ft 9 in (236.2cm)",
  },
  {
    value: "7 ft 10 in",
    label: "7 ft 10 in (238.8cm)",
  },
  {
    value: "7 ft 11 in",
    label: "7 ft 11 in (241.3cm)",
  },
  {
    value: "8 ft 0 in or more",
    label: "8 ft 0 in (243.8cm) or more",
  },
];

export default function App(props) {
  const [isDriver, setIsDriver] = useState();
  const [signatureStatus, setSignatureStatus] = useState("waiting");
  const [isWCAccessible, setIsWCAccessible] = useState(
    localStorage.getItem("showWC")
  );
  const [isMailingAddressDifferent, setIsMailingAddressDifferent] = useState(
    localStorage.getItem("mailingAddressDifferent") == "true" ? true : false
  );

  const [selectedBusinessEntity, setSelectedBusinessEntity] = useState(
    localStorage.getItem("selectedBusinessEntity")
  );

  const [selectedWorkerStatus, setSelectedWorkerStatus] = useState(
    localStorage.getItem("selectedWorkerStatus")
  );
  const [
    selectedAlienAuthorizedDocType,
    setSelectedAlienAuthorizedDocType,
  ] = useState(localStorage.getItem("selectedAlienAuthorizedDocType"));

  const sigPad = useRef();
  const isSignedInput = useRef();
  const { setFieldValue } = useFormikContext();
  const [fieldInput, fieldMeta, fieldHelpers] = useField("showWC.name");

  useEffect(() => {
    setIsDriver(props.isDriver);
  }, [props.isDriver]);

  useEffect(() => {
    console.log("here", sigPad.current);
    const handleEndStroke = () => {
      console.log("endstroke");
      console.log("isEmpty", sigPad.current.isEmpty());

      setSignatureStatus("signed");

      var event = new Event("input", { bubbles: true });
      isSignedInput.current.dispatchEvent(event);

      // set save status to local storage to be used in the next page
      localStorage.setItem("isSignedSaved", "false");

      // save to storage
      const peopleId = localStorage.getItem("i");
      const signature = sigPad.current.toDataURL();

      storage
        .ref(peopleId + "/signature.png")
        .putString(signature.split(",")[1], "base64", {
          contentType: "image/png",
        })
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((fileLink) => {
          console.log("sig fileLink", fileLink);
          localStorage.setItem("fileLink", fileLink);

          const requestOptions = {
            method: "PUT",
            body: fileLink,
          };

          fetch(
            `${url}/people/sql/onboarding/signatureUrl/${token}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              localStorage.setItem("isSignedSaved", "true");
            });
        });
    };

    if (!!sigPad.current && !!sigPad.current.signaturePad) {
      const current = sigPad.current;
      // initiate the event handler
      current.signaturePad.addEventListener(
        "endStroke",
        handleEndStroke,
        false
      );
      // clean up the event handler
      return function cleanup() {
        current.signaturePad.removeEventListener("endStroke", handleEndStroke);
      };
    }
  }, [sigPad]);

  useEffect(() => {
    if (signatureStatus == "cleared") {
      setNativeValue(isSignedInput.current, "cleared");

      var event = new Event("input", { bubbles: true });
      isSignedInput.current.dispatchEvent(event);

      console.log("cleared");
    } else {
      setNativeValue(isSignedInput.current, "signed");

      console.log("signed");
    }
  }, [signatureStatus]);

  // useEffect(() => {
  //   const mailingAddressDifferent = localStorage.getItem(
  //     "mailingAddressDifferent"
  //   );
  //   setFieldValue(
  //     "mailingAddressDifferent",
  //     mailingAddressDifferent == "true" ? "false" : "true"
  //   );
  // }, []);

  const {
    formField: {
      chkInstructions,
      chkDocs,
      chkReady,

      firstName,
      middleName,
      lastName,
      email,
      emailConfirm,
      phoneNumber,
      physicalAddress,
      physicalApt,
      physicalCity,
      physicalState,
      physicalZip,

      mailingAddressDifferent,
      mailingAddress,
      mailingApt,
      mailingCity,
      mailingState,
      mailingZip,

      emergencyFirstName,
      emergencyLastName,
      emergencyPhoneNumber,
      emergencyRelation,

      licenseNumber,
      licenseExpiryDate,

      ssn,
      ssnConfirm,
      birthDate,

      employerID,
      businessEntity,

      birthState,
      birthCountry,

      workerStatus,
      alienNumber,
      admissionNumber,
      alienAuthorizedDocType,
      alienAuthorizedExpiryDate,
      foreignPassportNumber,
      foreignPassportCountry,

      gender,
      heightFeet,
      weight,
      hairColor,
      eyeColor,

      licensePlateNumber,
      licensePlateState,
      vin,
      registrationRenewalDate,

      vehicleYear,
      vehicleColor,
      vehicleMake,
      vehicleModel,

      vehicleCapacity,
      vehicleCarSeatCapable,
      vehicleClass,

      vehicleWCAccessible,
      vehicleWCType,
      vehicleWCQty,
      showWC,

      isSigned,
    },
  } = props;

  function handleChange(e) {
    console.log("handle change called", e);
  }

  function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      "value"
    ).set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  }

  return (
    <>
      <div
        style={{
          textAlign: "left",
          paddingTop: "10px",
        }}
      >
        <Typography component="h5" variant="h5">
          Before we begin...
        </Typography>
        <div style={{ textAlign: "left", paddingTop: "20px" }}></div>
        <div
          style={{
            backgroundColor: "rgb(250, 227, 231, 0.6)",
            padding: "10px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <CheckboxField
                name={chkInstructions.name}
                label={chkInstructions.label}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <CheckboxField
                name={chkDocs.name}
                label={chkDocs.label}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <CheckboxField
                name={chkReady.name}
                label={chkReady.label}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        ></div>

        <div className={styles.sectionTitle}>
          <Typography component="h5" variant="h5">
            Contact Info
          </Typography>
        </div>
        <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InputField
              name={firstName.name}
              label={firstName.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              name={middleName.name}
              label={middleName.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField name={lastName.name} label={lastName.label} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField name={email.name} label={email.label} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={emailConfirm.name}
              label={emailConfirm.label}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              name={phoneNumber.name}
              label={phoneNumber.label}
              InputProps={{
                inputComponent: TextMaskPhone,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <div
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        ></div>

        <div className={styles.sectionTitle}>
          <Typography component="h5" variant="h5">
            Physical Address (cannot be a PO box)
          </Typography>
        </div>

        <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              name={physicalAddress.name}
              label={physicalAddress.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={physicalApt.name}
              label={physicalApt.label}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InputField
              name={physicalCity.name}
              label={physicalCity.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              name={physicalState.name}
              label={physicalState.label}
              data={states}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              name={physicalZip.name}
              label={physicalZip.label}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <CheckboxField
              name={mailingAddressDifferent.name}
              label={mailingAddressDifferent.label}
              onChange={(e) => {
                localStorage.setItem(
                  "mailingAddressDifferent",
                  e.target.checked
                );
                setIsMailingAddressDifferent(e.target.checked);
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        {isMailingAddressDifferent == true ? (
          <>
            <div
              style={{
                textAlign: "left",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            ></div>
            <div className={styles.sectionTitle}>
              <Typography component="h5" variant="h5">
                Mailing Address (different from physical address)
              </Typography>
            </div>
            <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputField
                  name={mailingAddress.name}
                  label={mailingAddress.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  name={mailingApt.name}
                  label={mailingApt.label}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <InputField
                  name={mailingCity.name}
                  label={mailingCity.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectField
                  name={mailingState.name}
                  label={mailingState.label}
                  data={states}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  name={mailingZip.name}
                  label={mailingZip.label}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
        <div
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        ></div>
        <div className={styles.sectionTitle}>
          <Typography component="h5" variant="h5">
            Emergency Contact
          </Typography>
        </div>
        <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              name={emergencyFirstName.name}
              label={emergencyFirstName.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={emergencyLastName.name}
              label={emergencyLastName.label}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              name={emergencyPhoneNumber.name}
              label={emergencyPhoneNumber.label}
              InputProps={{
                inputComponent: TextMaskPhone,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={emergencyRelation.name}
              label={emergencyRelation.label}
              fullWidth
            />
          </Grid>
        </Grid>
        <div
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        ></div>
        <div className={styles.sectionTitle}>
          <Typography component="h5" variant="h5">
            {isDriver ? "Driver Information" : "Monitor Information"}
          </Typography>
        </div>
        <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              name={licenseNumber.name}
              label={
                isDriver ? licenseNumber.label : licenseNumber.labelMonitor
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePickerField
              name={licenseExpiryDate.name}
              label={
                isDriver
                  ? licenseExpiryDate.label
                  : licenseExpiryDate.labelMonitor
              }
              format="MM/dd/yyyy"
              views={["year", "month", "date"]}
              minDate={new Date()}
              maxDate={new Date("2050/12/31")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* allow only 16yrs and above calculated from today */}
            <DatePickerField
              name={birthDate.name}
              label={birthDate.label}
              format="MM/dd/yyyy"
              views={["year", "date"]}
              minDate={new Date("1940/12/31")}
              maxDate={new Date() - 16 * 365 * 24 * 60 * 60 * 1000}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectField
              name={gender.name}
              label={gender.label}
              data={genderList}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              name={heightFeet.name}
              label={heightFeet.label}
              data={heightList}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField name={weight.name} label={weight.label} fullWidth />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectField
              name={hairColor.name}
              label={hairColor.label}
              data={hairColorList}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              name={eyeColor.name}
              label={eyeColor.label}
              data={eyeColorList}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>

        <div
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        ></div>
        <div className={styles.sectionTitle}>
          <Typography component="h5" variant="h5">
            Information for E-Verify, Background Check, IRS
          </Typography>
        </div>
        <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              name={ssn.name}
              label={ssn.label}
              InputProps={{
                inputComponent: TextMaskSSN,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={ssnConfirm.name}
              label={ssnConfirm.label}
              InputProps={{
                inputComponent: TextMaskSSN,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectField
              name={businessEntity.name}
              label={businessEntity.label}
              data={businessEntityList}
              onChange={(e) => {
                localStorage.setItem("selectedBusinessEntity", e.target.value);
                setSelectedBusinessEntity(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {selectedBusinessEntity === "2" ||
            selectedBusinessEntity === "3" ||
            selectedBusinessEntity === "4" ||
            selectedBusinessEntity === "5" ||
            selectedBusinessEntity === "6" ? (
              <InputField
                name={employerID.name}
                label={employerID.label}
                InputProps={{
                  inputComponent: TextMaskEmployerID,
                }}
                fullWidth
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectField
              name={birthState.name}
              label={birthState.label}
              data={states}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={birthCountry.name}
              label={birthCountry.label}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectField
              name={workerStatus.name}
              label={workerStatus.label}
              data={workerStatusList}
              onChange={(e) => {
                localStorage.setItem("selectedWorkerStatus", e.target.value);
                localStorage.setItem("selectedAlienAuthorizedDocType", "");
                setSelectedWorkerStatus(e.target.value);
                setSelectedAlienAuthorizedDocType(null);

                // reset entered values, if any
                setFieldValue("alienAuthorizedDocType", "");
                setFieldValue("alienNumber", "");
                setFieldValue("alienAuthorizedExpiryDate", "");
                setFieldValue("admissionNumber", "");
                setFieldValue("foreignPassportNumber", "");
                setFieldValue("foreignPassportCountry", "");
              }}
              fullWidth
            />
          </Grid>
          {selectedWorkerStatus === "3" ? (
            <Grid item xs={12} md={6}>
              <InputField
                name={alienNumber.name}
                label={alienNumber.label}
                fullWidth
              />
            </Grid>
          ) : (
            ""
          )}

          {selectedWorkerStatus === "4" ? (
            <Grid item xs={12} md={6}>
              <SelectField
                name={alienAuthorizedDocType.name}
                label={alienAuthorizedDocType.label}
                data={alienAuthorizedDocTypeList}
                onChange={(e) => {
                  localStorage.setItem(
                    "selectedAlienAuthorizedDocType",
                    e.target.value
                  );
                  setSelectedAlienAuthorizedDocType(e.target.value);

                  // reset entered values, if any
                  setFieldValue("alienNumber", "");
                  setFieldValue("alienAuthorizedExpiryDate", "");
                  setFieldValue("admissionNumber", "");
                  setFieldValue("foreignPassportNumber", "");
                  setFieldValue("foreignPassportCountry", "");
                }}
                fullWidth
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>

        <Grid container spacing={3}>
          {selectedAlienAuthorizedDocType === "1" ? (
            <>
              <Grid item xs={12} md={6}>
                <InputField
                  name={alienNumber.name}
                  label={alienNumber.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePickerField
                  name={alienAuthorizedExpiryDate.name}
                  label={alienAuthorizedExpiryDate.label}
                  format="MM/dd/yyyy"
                  views={["year", "month", "date"]}
                  minDate={new Date()}
                  maxDate={new Date("2050/12/31")}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            ""
          )}

          {selectedAlienAuthorizedDocType === "2" ? (
            <>
              <Grid item xs={12} md={6}>
                <InputField
                  name={admissionNumber.name}
                  label={admissionNumber.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePickerField
                  name={alienAuthorizedExpiryDate.name}
                  label={alienAuthorizedExpiryDate.label}
                  format="MM/dd/yyyy"
                  views={["year", "month", "date"]}
                  minDate={new Date()}
                  maxDate={new Date("2050/12/31")}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            ""
          )}

          {selectedAlienAuthorizedDocType === "3" ? (
            <>
              <Grid item xs={12} md={6}>
                <InputField
                  name={foreignPassportNumber.name}
                  label={foreignPassportNumber.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  name={foreignPassportCountry.name}
                  label={foreignPassportCountry.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <DatePickerField
                  name={alienAuthorizedExpiryDate.name}
                  label={alienAuthorizedExpiryDate.label}
                  format="MM/dd/yyyy"
                  views={["year", "month", "date"]}
                  minDate={new Date()}
                  maxDate={new Date("2050/12/31")}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>

        {isDriver && (
          <>
            <div
              style={{
                textAlign: "left",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            ></div>
            <div className={styles.sectionTitle}>
              <Typography component="h5" variant="h5">
                Vehicle Information
              </Typography>
            </div>
            <div style={{ textAlign: "left", paddingTop: "10px" }}></div>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputField
                  name={licensePlateNumber.name}
                  label={licensePlateNumber.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectField
                  name={licensePlateState.name}
                  label={licensePlateState.label}
                  data={states}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputField name={vin.name} label={vin.label} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePickerField
                  name={registrationRenewalDate.name}
                  label={registrationRenewalDate.label}
                  format="MM/dd/yyyy"
                  views={["year", "month", "date"]}
                  minDate={new Date()}
                  maxDate={new Date("2050/12/31")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <SelectField
                  name={vehicleYear.name}
                  label={vehicleYear.label}
                  data={vehicleYearList}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectField
                  name={vehicleColor.name}
                  label={vehicleColor.label}
                  data={vehicleColorList}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <SelectField
                  name={vehicleMake.name}
                  label={vehicleMake.label}
                  data={vehicleMakeList}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputField
                  name={vehicleModel.name}
                  label={vehicleModel.label}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <SelectField
                  name={vehicleClass.name}
                  label={vehicleClass.label}
                  data={vehicleClassList}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectField
                  name={vehicleCapacity.name}
                  label={vehicleCapacity.label}
                  data={count10}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectField
                  name={vehicleCarSeatCapable.name}
                  label={vehicleCarSeatCapable.label}
                  data={listYesNo}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <SelectField
                  name={vehicleWCAccessible.name}
                  label={vehicleWCAccessible.label}
                  data={listYesNo}
                  onChange={(e) => {
                    if (e.target.value === "Yes") {
                      setIsWCAccessible("true");
                      setFieldValue("showWC", true);
                      localStorage.setItem("showWC", "true");
                    } else {
                      setIsWCAccessible("false");
                      setFieldValue("showWC", false);
                      localStorage.setItem("showWC", "false");
                    }
                  }}
                  fullWidth
                />
              </Grid>
              {isWCAccessible === "true" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <SelectField
                      name={vehicleWCType.name}
                      label={vehicleWCType.label}
                      data={wcVanType}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SelectField
                      name={vehicleWCQty.name}
                      label={vehicleWCQty.label}
                      data={count10}
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </>
        )}
        <div
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        ></div>
      </div>
      <br />
      <div className={styles.sectionTitle}>
        <Typography component="h5" variant="h5">
          Signature
        </Typography>
      </div>
      <div style={{ textAlign: "left", paddingTop: "10px" }}></div>
      <div className={sigStyles.sigContainer}>
        <div>
          <Button
            onClick={() => {
              sigPad.current.clear();
              setSignatureStatus("cleared");
            }}
          >
            Clear
          </Button>
        </div>
        <div>
          <SignaturePadWrapper
            ref={sigPad}
            options={{ penColor: "rgb(0, 0, 255)" }}
            canvasProps={{ className: sigStyles.sigPad }}
          />
        </div>
        <div>
          <InputField
            name={isSigned.name}
            inputRef={isSignedInput}
            InputProps={{
              readOnly: true,
              style: { color: "white", fontSize: "1px" },
            }}
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
